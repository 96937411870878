<template>
  <v-card width="500" height="200px" class="rounded-0" color="white">
    <v-form ref="form" @submit.prevent="handleSubmit">
      <v-card-text>
        <v-container>
          <v-row align="center" justify="center" class="ma-0 pa-0">
            <v-col cols="12" md="12" class="pa-0 ma-0">
              <v-text-field
                dense
                aria-autocomplete="off"
                label="Nombre"
                v-model="realEstateDevelopmentName"
                :hint="`Código generado: ${redCodeGenerated}`"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" :disabled="isActiveRequest" color="success"
          >Registrar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { CreateRealEstateDevelopment } from "@/api/real-estate-development-api.js";

export default {
  data() {
    return {
      isActiveRequest: false,
      realEstateDevelopmentName: "",
      realEstateDevelopmentAddress: ""
    };
  },
  computed: {
    /* eslint-disable no-useless-escape */
    redCodeGenerated() {
      const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:";
      const b = "aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------";
      const p = new RegExp(a.split("").join("|"), "g");
      if (
        !this.realEstateDevelopmentName ||
        this.realEstateDevelopmentName.length === 0
      )
        return "";
      return this.realEstateDevelopmentName
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim  - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    }
  },
  methods: {
    // listeners
    handleSubmit() {
      if (!this.isActiveRequest) {
        if (this.$refs.form.validate()) {
          this.handleCreateRealEstateDevelopment();
        }
      }
    },
    // end listeners
    handleCreateRealEstateDevelopment() {
      let self = this;
      this.$snotify.async(
        "Registrando desarrollo inmobiliario",
        "Registrando...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              self.sendCreateRealEstateDevelopment(resolve, reject);
            }, 1000);
          })
      );
    },
    sendCreateRealEstateDevelopment(resolveNotify, rejectNotify) {
      try {
        let self = this;
        let dataCreate = {
          code: self.redCodeGenerated,
          name: self.realEstateDevelopmentName,
          address: self.realEstateDevelopmentAddress
        };
        CreateRealEstateDevelopment(dataCreate)
          .then(() => {
            resolveNotify({
              title: "Registo correcto",
              body:
                "El desarrollo inmobiliario se ha registrado correctamente.",
              config: {
                closeOnClick: true,
                pauseOnHover: true,
                timeout: 2000
              }
            });
          })
          .catch(() => {
            rejectNotify({
              title: "Ocurrió un error",
              body:
                "Se originó un problema al agregar el desarrollo inmobiliario. Por el momento no se puede registrar.",
              config: {
                closeOnClick: true,
                pauseOnHover: true,
                timeout: 4000
              }
            });
          })
          .finally(() => {
            setTimeout(() => {
              self.clearForm();
              // window.location.href = `/edit/${self.redCodeGenerated}`
            }, 2050);
          });
      } catch (err) {
        setTimeout(
          () =>
            rejectNotify({
              title: "Error!!!",
              body:
                "Ocurrio un problema al agregar el desarrollo. Por el momento no se puede registrar el desarrollo.",
              config: {
                closeOnClick: true
              }
            }),
          2000
        );
      }
    },
    clearForm() {
      this.$refs.form.reset();
      this.isActiveRequest = false;
    }
  }
};
</script>
